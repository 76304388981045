var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "el-radio-group",
        {
          staticStyle: { "margin-left": "35px" },
          model: {
            value: _vm.couponType,
            callback: function($$v) {
              _vm.couponType = $$v
            },
            expression: "couponType"
          }
        },
        [
          _c("el-radio-button", {
            attrs: {
              label: "单品券",
              disabled: _vm.formData.type !== 1 && _vm.type === "update"
            }
          }),
          _c("el-radio-button", {
            attrs: {
              label: "CPS平台券",
              disabled: _vm.formData.type !== 2 && _vm.type === "update"
            }
          }),
          _c("el-radio-button", {
            attrs: {
              label: "官方平台券",
              disabled: _vm.formData.type !== 3 && _vm.type === "update"
            }
          })
        ],
        1
      ),
      _vm.couponType == "单品券"
        ? _c("couponSingle", {
            attrs: { content: _vm.formData, type: _vm.type },
            on: { saveCoupon: _vm.saveCoupon }
          })
        : _vm.couponType == "官方平台券"
        ? _c("couponOfficial", {
            attrs: { content: _vm.formData, type: _vm.type },
            on: { saveCoupon: _vm.saveCoupon }
          })
        : _c("couponPlatform", {
            attrs: { content: _vm.formData, type: _vm.type },
            on: { saveCoupon: _vm.saveCoupon }
          })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }